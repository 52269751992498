@font-face {
    font-family: 'I-Plex';
    font-style: normal;
    font-weight: 100;
    src: url("./Fonts/IBMPlexSansKR-ExtraLight.woff2") format('woff2'),
    url("./Fonts/IBMPlexSansKR-ExtraLight.woff") format('woff'),
    url("./Fonts/IBMPlexSansKR-ExtraLight.otf") format('truetype')
}

@font-face {
    font-family: 'I-Plex';
    font-style: normal;
    font-weight: 300;
    src: url("./Fonts/IBMPlexSansKR-Light.woff2") format('woff2'),
    url("./Fonts/IBMPlexSansKR-Light.woff") format('woff'),
    url("./Fonts/IBMPlexSansKR-Light.otf") otf('truetype')
}

@font-face {
    font-family: 'I-Plex';
    font-style: normal;
    font-weight: 400;
    src: url("./Fonts/IBMPlexSansKR-Regular.woff2") format('woff2'),
    url("./Fonts/IBMPlexSansKR-Regular.woff") format('woff'),
    url("./Fonts/IBMPlexSansKR-Regular.otf") otf('truetype')
}

@font-face {
    font-family: 'I-Plex';
    font-style: normal;
    font-weight: 600;
    src: url("./Fonts/IBMPlexSansKR-SemiBold.woff2") format('woff2'),
    url("./Fonts/IBMPlexSansKR-SemiBold.woff") format('woff'),
    url("./Fonts/IBMPlexSansKR-SemiBold.otf") otf('truetype')
}

@font-face {
    font-family: 'I-Plex';
    font-style: normal;
    font-weight: bold;
    src: url("./Fonts/IBMPlexSansKR-Bold.woff2") format('woff2'),
    url("./Fonts/IBMPlexSansKR-Bold.woff") format('woff'),
    url("./Fonts/IBMPlexSansKR-Bold.otf") otf('truetype')
}


@font-face {
    font-family: 'EMOJI';
    src: url('./Fonts/AppleEmoji.eot');
    src: url('./Fonts/AppleEmoji.woff') format('woff');
    src: url('./Fonts/AppleEmoji.woff2') format('woff2');
    src: url('./Fonts/AppleEmoji.ttf') format('truetype');
    src: url('./Fonts/AppleEmoji.svg#Apple Color Emoji') format('svg');
}

body{
    margin:0;
    font-family: 'I-Plex';
    font-weight: 600;
}