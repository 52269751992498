.sidebar{
    list-style-type: none;
    margin: 0;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px 0.1px rgba(0, 0, 0, 0.1);
    height: 1080px;
}

.sidebox{
    background-color: #F3F3F3;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.15);
}

.sidebar_card{
    width: auto;
    padding: 5px 5px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.1);
    color: #272727;
    text-align: center;
}

/* Profile */

.information{
    background-color: #F3F3F3;
    margin-bottom: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.15);
    height: auto;
    padding-bottom: 20px;
}

.profileholder{
    display: flex;
}
.profilepic{
    background-color: #8a8a8a;
    height: 80px;
    width: 80px;
    margin: 20px auto;
    border-radius: 50%;
}

.username{
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
}

.username span{
    color: #5D73BD;
    font-weight: 400;
}

.cash span{
    font-size: 10px;
    font-weight: 100;
    color: #999999;
}

.cash_amount{
    font-size: 12px;
    font-weight: 400;
    color: #5D73BD;
    padding-bottom: 5px;
}

.slider{
    margin-top: 10px;
    background-color: #FBFBFB;
    width: auto;
    padding: 5px 5px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.1);
    color: #272727;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.slider_box a{
    padding-left: 5px;
    padding-right: 5px;
    color: #999999;
    font-size: 10px;
    transition: 0.4s;
    padding-top: 1px;
    padding-bottom: 1px;
    text-decoration: none;
}

.slider_box a:hover{
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0.1px rgba(0, 0, 0, 0.1);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 5px;
    color: #5D73BD;
    font-size: 10px;
    transition: 0.4s;
    font-weight: 400;
    text-decoration: none;
}

.slider_text{

}

.slider_icon{
    margin-top: -2px;
    vertical-align:middle;
}

/* Sidebar Items */
.item, .dropdownBtn, .dropdownItem{
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    cursor: pointer;
    border-left: 0px solid transparent;
    padding: 12px 20px;
    transition: 0.5s;
    font-size: 14px;
    color: #999999;
    font-weight: 100;
    display: block;
}
.select, .item:hover, .dropdownItem:hover{
    border-left: 3px solid #5D73BD;
    font-weight: 400;
    color: #272727;
    transition: 0.5s;
}

.dropdownContainer {
    display: none;
}
.icon, .dropdownIcon{
    margin-top: -2px;
    vertical-align:middle;
}
.dropdownItem{
    padding-left: 50px;
    transition: 0.5s;
}
.dropdownIcon{
    opacity: 0.6;
}
a{
    font-size: 14px;
    color: #999999;
    font-weight: 100;
    display: block;
}