.wrap{
    display: grid;
    grid-template-columns: 260px auto;
    row-gap: 0px;
    column-gap: 16px;
}

.content{
    display: grid;
    grid-template-rows: repeat(2, 250px);
}

.announcement{
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0.1px rgba(0, 0, 0, 0.2);
    height: 210px;
    margin: 20px;
}

.announcement_title{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    border-bottom: 1px solid #c2c2c2;
}

.announcement_desc{
    padding: 5px;
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 5px;
    background-color: #F3F3F3;
}

.announcement_desc_content{
    font-size: 15px;
    margin-left: 20px;
}

.announcement_details{
    font-size: 12px;
    margin-left: 25px;
}

.button_wrap{
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-left: 20px;
    margin-right: 20px;
}

.button{
    text-decoration: none;
    color: #272727;
    font-weight: 700;
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0.1px rgba(0, 0, 0, 0.1);
    height: 300px;
    width: 100%;
    align-items: center;
}

.button_long{
    text-decoration: none;
    color: #272727;
    font-weight: 700;
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0.1px rgba(0, 0, 0, 0.1);
    height: 80px;
    width: 100%;
    align-items: center;
	grid-column: 1 / 3;
	grid-row: 2 / 2;
}

.button_long_content_wrap{
    position: relative;
    top: 0px;
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.button_long_logo{
    font-size: 40px;
    text-align: right;
    position: relative;
    top: 13px;
}

.button_long_title{
    position: relative;
    top: 23px;
    font-size: 15px;
    text-align: left;
}

.button_long_desc{
    font-size: 10px;
    text-align: left;
}

.button_content_wrap{
    position: relative;
    top: 50px;
}

.button_logo{
    font-size: 70px;
    text-align: center;
}

.button_title{
    font-size: 30px;
    text-align: center;
}

.button_desc{
    font-size: 15px;
    text-align: center;
}