.nav_header{
    background-color: #3B3B3B;
    height: 60px;
    max-width: 100%;
    grid-column: 1 / span 2;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    vertical-align: middle;
}

.nav_logo_holder{
    width: 260px;
    height: 100%;
    background-color: #575757;
    vertical-align: middle;
}

.nav_logo{
    color: #FFF;
    font-size: 20px;
    text-align: center;
    position: relative;
    top: 24%;
}

.badge_holder{
    display:grid;
    grid-template-rows: 2fr 1fr;
    height: 70%;
    position: relative;
    background-color: #575757;
    margin-right: 20px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    top: 7px;
    align-items: center;
    border-radius: 5px;
}

.badge{
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    font-size: 15px;
}

.badge_desc{
    text-align: center;
    font-size: 8px;
    color: #ffffff;
}

.badge_desc span{
    color: #6babff;
    font-weight:700;
}