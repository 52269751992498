.root{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F3F3F3;
}

/* Grid Container */
.wrap{
    display: grid;
    grid-template-columns: 260px auto;
    row-gap: 0px;
    column-gap: 16px;
}

/* Grid Items */
.content{
    display: grid;
    padding: 20px 20px;
}

.box{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 300px);
    background-color: #FFFFFF;
    padding: 30px;
    gap: 16px;
    border: 16px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0.1px rgba(0, 0, 0, 0.1);
}

.card{
    width: auto;
    height: 250px;
    padding: 20px 20px;
    background-color: #FFFFFF;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0.1px rgba(0, 0, 0, 0.2);
    color: #272727;
}

.title{
    font-size: 18px;
    font-weight: 200;
}

.desc {
    font-size: 11px;
    color: #aaaaaa;
    padding-bottom: 10px;
}

.desc i {
    font-style: normal !important;
    color: #5D73BD;
}

.title span{
    color: #5D73BD;
}

.contents{
    padding: 5px;
    border-top: 1px solid #c2c2c2;
}
.contents_card{
    /* 추후 그리드 옵션 적용 위해 남겨둠*/
}

.live_log_content{
    grid-column: 2/2;
    color: #565656;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
}

@keyframes cateBgAni {
    from {
      background-position: 0%;
    }
    to {
      background-position: 600%;
    }
  }

.contents .contents_card em.success{
    background-color: #81a1e6;
}

.contents .contents_card em.fail{
    background-color: #e6818e;
}

.contents .contents_card em.green{
    background-color: #79e78b;
}

.contents .contents_card em{
    font-style: normal;
    position: relative;
    display: inline-block;
    width: 40px;
    font-size: 8px;
    text-align: center;
    padding: 5px;   
    color: #fff;
    background: linear-gradient(45deg, transparent, transparent, rgba(255, 255, 255, 0.3), transparent, transparent);
    background-size: 150%;
    transition: all 0.2s;
    border-radius: 3px;
    animation: cateBgAni 4s linear infinite;
    margin-right: 0.5rem;
}

.notice_content{
    color: #565656;
    font-size: 15px;
    font-weight: 300;
}


p{
    margin-top: 5px;
    margin-bottom: 5px;
}